export const TOKEN_TYPE = "Bearer ";
export const REQUEST_HEADER_AUTH_KEY = "Authorization";

///End points
export const APIS = {
  GET_USER_LISTING: "/listData",
  LIST_TRIPS: "/listTrips",
  LIST_BILLS: "/listBills",
  FINAL_SUBMIT_BILLS: "/finalSubmitBills",
  REPORTS: "/reports",
  BLOCK_DELETE_DATA: "/blockDeleteData",
  UPLOAD_IMAGE: "api/uploadImage",
  DELETE_IMAGE: "api/deleteImageBucket",
  GET_APP_DEFAULTS: "/appDefaults",

  ADD_EDIT_TRANSPORT_MANAGERS: "/addEditTransportManagers",
  ADD_EDIT_DESTINATION_PARTY: "/addEditDestinationParty",
  ADD_EDIT_SUB_ADMIN: "/addEditSubAdmin",
  ADD_EDIT_TRANSPORTERS: "/addEditTransporters",
  ADD_EDIT_DRIVER: "/addEditDriver",
  ADD_EDIT_TRIPS: "/addEditTrip",
  ADD_EDIT_APP_DEFAULTS: "/addEditAppDefaults",
  ADD_EDIT_FINANCE: "/addEditFinance",
  ADD_EDIT_CSD: "/addEditCsdQuality",
  ADD_EDIT_MIS: "/addEditMisManager",
  ADD_EDIT_QUESTIONS: "/addEditQuestion",
  ADD_EDIT_TRIP_BILLS: "/addEditTripBills",

  GET_TRIP_BILLS: "/tripBills",
  GET_DEFAULTS: "/api/getDefaults",
  TRIP_DETAILS: "api/tripDetails",
  REFRESH_TRIPS: "/refreshTrips",
  GET_IN_TRANSIT_TRIPS: "api/intransitTrips",

  ADD_RAISE_INCIDENCE: "/raiseIncidence",
  ADD_RAISE_COMPLAINT: "/raiseComplaint",

  IMPORT_DRIVERS: "/importFile",
  EXPORT_DRIVERS: "/exportFile",

  EXPORT_REPORTS: "/reportsExport",
  TRIPS_EXPORT_REPORTS: "/tripsExport",

  DASHBOARD: "/dashboard",
  EXPORT_BILLS: "/exportBills",
  ACTIVITY_LOGS: "/activityLogs",
  GET_NOTIFICATION: "/notifications",
  READ_NOTIFICATION: "/notificationAction",
  DASHBOARD_OTHER_STATS: "/otherStats",

  LIST_QUESTIONS: "/listQuestions",
  START_POD: "/startPod",
  SAVE_POD: "/savePod",
  POD_QUESTIONS: "/podQuestions",
  VERIFY_POD: "/verifyPod",
  RESEND_POD_OTP: "/resendPodOTP",
};

export const COMMON_APIS = {
  VERIFY_OTP: "api/verifyOtp",
  LOGOUT: "/logout",
  CHANGE_PASSWORD: "/changePassword",
};
